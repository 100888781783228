import React from "react";
import { useTranslation } from "react-i18next";
import { WEBSITE_NAME } from "../../constants/CompanyConstants";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { device } from "../../utils/breakpoints";
import { privacy, terms_conditions } from "../../urls";
import { Link } from "react-router-dom";
import { transparentize } from "../../utils/helperFn";

const FooterBottom = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Wrapper>
        <Column lg={4}>
          <TextBold>{WEBSITE_NAME}</TextBold>
        </Column>
        <Column lg={4}>
          <StyledLink to={terms_conditions}>
            {t("terms_and_conditions")}
          </StyledLink>
        </Column>
        <Column lg={4}>
          <StyledLink to={privacy}>{t("privacy_policy")}</StyledLink>
        </Column>
      </Wrapper>
    </Container>
  );
};

const Column = styled(Col)`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  font-weight: 300;
  margin-bottom: 0.9rem;
  color: ${transparentize("#FFFFFF", 0.8)};

  &:hover {
    color: #fff;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  margin-bottom: 0.9rem !important;
  color: #fff;
`;

const TextBold = styled(Text)`
  font-weight: 600;
`;

const Wrapper = styled(Row)`
  padding: 30px 60px 10px 60px;
  border-top: 1px solid #ffffff12;
  @media ${device.maxLg} {
    padding: 30px 40px 10px 40px;
  }
`;

export default FooterBottom;
