export const CURRENCY = "€ ";
export const CURRENCY_CODE = "EUR";
export const DELIVERY_OPTIONS = {
  PICKUP: "pickup",
  SEND: "send",
};

export const PAYMENT_METHODS = {
  CREDITCARD: "creditcard",
  IDEAL: "ideal",
  BANCONTACT: "bancontact",
};

export const ADDRESS_TYPES = {
  DELIVERY: "DELIVERY",
  INVOICE: "INVOICE",
};
