import React from "react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../../translations/i18n";
import { WEBSITE_PHONE_NUMBER } from "../../../constants/CompanyConstants";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { getCurrentLanguageFlag } from "../../../helpers/language";
import { device } from "../../../utils/breakpoints";
import languageChangerData from "../../../data/language/language-chooser.json";

const LanguageChanger = ({ small = false }) => {
  const { t } = useTranslation();
  const currentFlag = getCurrentLanguageFlag();

  if (small)
    return (
      <SmallChangerWrapper>
        <IconWrapper>
          <FlagIcon
            src={currentFlag.src}
            alt={currentFlag.alt}
            id={"flag-icon"}
          />
        </IconWrapper>
        <LanguageDropDown id={"small-lang-dropdown"}>
          {languageChangerData.map((item, index) => {
            return (
              <button
                onClick={() => changeLanguage(item.lang_code)}
                key={index}
              >
                <div className={"d-flex"}>
                  <DropDownFlagIcon src={item.src} alt={item.alt} />
                  {item.text}
                </div>
              </button>
            );
          })}
        </LanguageDropDown>
      </SmallChangerWrapper>
    );
  else
    return (
      <div className="language-currency-wrap">
        <div className="same-language-currency language-style">
          <span>
            {t("language")} <i className="fa fa-angle-down" />
          </span>
          <div className="lang-car-dropdown">
            <ul>
              {languageChangerData.map((item, index) => {
                return (
                  <li key={index}>
                    <button onClick={() => changeLanguage(item.lang_code)}>
                      {item.text}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="same-language-currency">
          <p>
            {t("call_us")} {WEBSITE_PHONE_NUMBER}
          </p>
        </div>
      </div>
    );
};

const DropDownFlagIcon = styled.img`
  width: 20px;
  margin-right: 4px;
`;

const LanguageDropDown = styled.div`
  position: absolute;
  z-index: 220;
  top: 100%;
  visibility: hidden;
  width: max-content;
  padding: 8px 12px 10px;
  transition: all 0.5s ease 0s;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  text-align: left;
  opacity: 0;
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 1px 7px -2px rgb(0 0 0 / 30%);

  button {
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 27px;
    border: none;
    background: none;

    &:hover {
      color: ${colors.primary};
    }
  }
`;

const FlagIcon = styled.img`
  width: 30px;
`;

const IconWrapper = styled.div``;

const SmallChangerWrapper = styled.div`
  display: none;
  align-self: center;
  cursor: pointer;

  &:hover {
    #small-lang-dropdown {
      visibility: visible;
      transform: rotateX(0deg);
      opacity: 1;
    }

    #flag-icon {
      opacity: 0.7;
    }
  }

  @media ${device.lg} {
    display: block;
  }
`;

export default LanguageChanger;
