import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import {
  COGNICUM_CONTACT_URL,
  SOCIAL_MEDIA,
} from "../../constants/CompanyConstants";
import FooterOpeningHours from "../../components/footer/FooterOpeningHours";
import openingHoursNL from "../../data/company/opening_hours.json";
import openingHoursEN from "../../data/company/opening_hours_en.json";
import i18n from "../../translations/i18n";
import { LANGUAGE_NL } from "../../constants/LanguageConstants";
import FooterBottom from "../../components/footer/FooterBottom";

const FooterOne = ({ containerClass }) => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [openingHoursData, setOpeningHoursData] = useState(openingHoursNL);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setOpeningHoursData(
      i18n.language === LANGUAGE_NL ? openingHoursNL : openingHoursEN
    );
  }, [i18n.language]);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer className={"footer-area pt-70"}>
      <div className={`container`}>
        <div className="row">
          <div className={"col-lg-3 col-sm-12"}>
            {/* footer copyright */}
            <FooterCopyright
              footerLogo="/assets/img/logo/logo.png"
              spaceBottomClass="mb-30"
            />
          </div>
          <div className={"col-lg-3 col-sm-12"}>
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{t("your_trip")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={urls.booking_overview}>{t("view_booking")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-3 col-sm-12"}>
            <div className={"footer-widget mb-30 ml-50"}>
              <div className="footer-title">
                <h3>{t("customer_service")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <a href={COGNICUM_CONTACT_URL}>{t("contact")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-3 col-sm-12"}>
            <div className={"footer-widget mb-30 ml-75"}>
              <FooterOpeningHours data={openingHoursData} />
              <div className="footer-list">
                <ul>
                  {SOCIAL_MEDIA.length > 0 && (
                    <div className="off-canvas-widget-social mt-5">
                      {SOCIAL_MEDIA.map((item, key) => {
                        return (
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={"pl-2 pr-2"}
                            key={key}
                          >
                            <i className={item.icon} />
                          </a>
                        );
                      })}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />

      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up" />
      </button>
    </footer>
  );
};

FooterOne.propTypes = {
  containerClass: PropTypes.string,
};

export default FooterOne;
