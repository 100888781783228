import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { device } from "../../utils/breakpoints";
import { Link } from "react-router-dom";
import urls from "../../urls";
import { useSelector } from "react-redux";
import _ from "lodash";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const { t } = useTranslation();
  const cartData = useSelector((state) => state.cartData);
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  let cartItemsAmount = _.sumBy(cartData, "quantity");

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <DesktopHeader
        className={`header-area clearfix ${
          headerBgClass ? headerBgClass : ""
        } ${headerPositionClass ? headerPositionClass : ""}`}
      >
        <div
          className={`${headerPaddingClass ? headerPaddingClass : ""} ${
            top === "visible" ? "d-none d-lg-block" : "d-none"
          } header-top-area ${
            borderStyle === "fluid-border" ? "border-none" : ""
          }`}
        >
          <div className={layout === "container-fluid" ? layout : "container"}>
            {/* header top */}
            <HeaderTop borderStyle={borderStyle} />
          </div>
        </div>

        <div
          className={` ${
            headerPaddingClass ? headerPaddingClass : ""
          } sticky-bar header-res-padding clearfix bg-shadow ${
            scroll > headerTop ? "stick" : ""
          }`}
        >
          <div className={layout === "container-fluid" ? layout : "container"}>
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-6 col-4 align-self-center">
                {/* header logo */}
                <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" />
              </div>
              <div className="col-xl-8 col-lg-8 d-none d-lg-flex justify-content-end">
                {/* Nav menu */}
                <NavMenu />
                {/*<BackToMainWebsite href={COGNICUM_WEBSITE}>*/}
                {/*  <ArrowLeftOutlined />*/}
                {/*  <span>{t("back_to_cognicum_main")}</span>*/}
                {/*</BackToMainWebsite>*/}
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-8">
                {/* Icon group */}
                <IconGroup />
              </div>
            </div>
          </div>
        </div>
      </DesktopHeader>
      <MobileHeader>
        <div
          className={`sticky-bar header-res-padding clearfix bg-shadow ${
            scroll > headerTop ? "stick" : ""
          }`}
        >
          <MobileItemsWrapper>
            <HamburgerMenu
              onClick={() => setMenuOpen(!menuOpen)}
              className={menuOpen ? "active" : ""}
            >
              {/*<input />*/}
              <span />
              <span />
              <span />
            </HamburgerMenu>
            {/* header logo */}
            <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" />
            <CartLink className="icon-cart" to={urls.cart}>
              <CartIcon />
              <Counter>
                <span>{cartItemsAmount}</span>
              </Counter>
            </CartLink>
          </MobileItemsWrapper>
          {/* mobile menu */}
          <MobileMenu active={menuOpen} />
        </div>
      </MobileHeader>
    </>
  );
};

const HamburgerMenu = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  align-self: center;
  -webkit-user-select: none;
  user-select: none;

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;

    background: ${colors.hamburger};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -3px);
      background: ${colors.hamburger};
    }

    span:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    span:nth-last-child(1) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
`;

const MobileItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const CartLink = styled(Link)`
  font-size: 36px;
  position: relative;
  display: inline-block;
  border: none;
  background: transparent;
  align-self: center;
`;

const Counter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  top: -12px;
  right: -9px;
  width: 20px;
  height: 20px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.primary};

  span {
    align-self: center;
  }
`;

const CartIcon = styled.div`
  width: 25px;
  height: 25px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/cart.svg") no-repeat center /
    contain;
  mask: url("/assets/img/button-icons/cart.svg") no-repeat center / contain;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.primary};
  }

  @media ${device.lg} {
    width: 32px;
    height: 32px;
  }
`;

const MobileHeader = styled.div`
  display: block;
  @media ${device.lg} {
    display: none;
  }
`;

const DesktopHeader = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`;

const BackToMainWebsite = styled.a`
  align-self: center;
  display: flex;

  .anticon {
    margin-right: 0.35rem;
    font-size: 15px;
    line-height: 24px;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    color: ${colors.text};
  }
`;

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
