import PropTypes from "prop-types";
import React from "react";
import {
  SOCIAL_MEDIA,
  WEBSITE_EMAIL_ADDRESS,
  WEBSITE_PHONE_NUMBER,
} from "../../constants/CompanyConstants";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { MailFilled, PhoneFilled } from "@ant-design/icons";

const HeaderTop = ({ borderStyle }) => {
  return (
    <Wrapper
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <div className={"d-flex"}>
        <p className={"mr-4"}>
          <PhoneFilled style={{ fontSize: 16 }} />{" "}
          <a href={`tel:${WEBSITE_PHONE_NUMBER}`}>{WEBSITE_PHONE_NUMBER}</a>
        </p>
        <p>
          <MailFilled style={{ fontSize: 16 }} />{" "}
          <a href={`mailto:${WEBSITE_EMAIL_ADDRESS}`}>
            {WEBSITE_EMAIL_ADDRESS}
          </a>
        </p>
      </div>
      {SOCIAL_MEDIA.length > 0 && (
        <div className={"d-flex"}>
          {SOCIAL_MEDIA.map((item, key) => {
            return (
              <SocialIcon
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                key={key}
              >
                <i className={item.icon} />
              </SocialIcon>
            );
          })}
        </div>
      )}
      {/*<p className={"slogan"}>{t("slogan")}</p>*/}
    </Wrapper>
  );
};

const SocialIcon = styled.a`
  display: flex;
  height: 28px;
  width: 28px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  margin-left: 8px;
  border-radius: 2px;
  transition: 0.3s all ease;

  &:hover {
    background-color: ${colors.secondary};
  }

  i {
    font-size: 16px;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  height: 54px;

  p {
    line-height: 1;
    letter-spacing: 0.8px;
    margin-bottom: 0;

    &.slogan {
      color: ${colors.primary};
    }
  }
`;

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
