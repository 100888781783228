import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import {
  CalendarOutlined,
  HomeOutlined,
  IdcardOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

const NavMenu = () => {
  const { t } = useTranslation();
  return (
    <div className={"main-menu"}>
      <nav>
        <ul>
          <li>
            <NavLink to={urls.home} activeClassName="active" exact>
              <HomeOutlined style={{ marginTop: -5, marginRight: 4 }} />
              {t("collection")}
            </NavLink>
          </li>
          <li>
            <a href={urls.cognicum_about_us} activeClassName="active" exact>
              <IdcardOutlined style={{ marginTop: -5, marginRight: 4 }} />
              {t("about_us")}
            </a>
          </li>
          <li>
            <a href={urls.cognicum_agenda} activeClassName="active" exact>
              <CalendarOutlined style={{ marginTop: -5, marginRight: 4 }} />
              {t("agenda")}
            </a>
          </li>
          <li>
            <a href={urls.cognicum_contact} activeClassName="active" exact>
              <PushpinOutlined style={{ marginTop: -5, marginRight: 4 }} />
              {t("contact")}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavMenu;
