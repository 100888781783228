import React, { lazy, Suspense } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import "./translations/i18n";
import urls from "./urls";
import ForgotPassword from "./pages/other/ForgotPassword";
import { useFirestoreConnect } from "react-redux-firebase";
import { Loader } from "./components/loader/Loader";
import moment from "moment";
import localization from "moment/locale/nl";
import { ORDER_STATUS_CREATED } from "./constants/OrderConstants";
import CookieBanner from "./components/banner/CookieBanner";
import {
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
} from "./constants/FirebaseConstants";

moment.locale("nl", localization);

// shop pages
const ShopListStandard = lazy(() => import("./pages/shop/ShopList"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const VerifyEmail = lazy(() => import("./pages/other/VerifyEmail"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmed = lazy(() => import("./pages/other/OrderConfirmed"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const OrderProcessing = lazy(() => import("./pages/other/OrderProcessing"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/other/TermsConditions"));
const ShippingReturns = lazy(() => import("./pages/other/ShippingReturns"));
const FAQ = lazy(() => import("./pages/other/FAQ"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

// Cognicum-specific
const BookingOverview = lazy(() => import("./pages/cognicum/BookingOverview"));

const App = () => {
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  let queries = [
    { collection: FIRESTORE_PRODUCTS_TABLE, where: ["archived", "==", false] },
    { collection: FIRESTORE_FEATURED_PRODUCTS_TABLE },
  ];
  // If user is logged in, get his/her orders
  if (!!email)
    queries.push({
      collection: FIRESTORE_ORDERS_TABLE,
      where: [
        ["customer.email", "==", email],
        ["orderStatus", "!=", ORDER_STATUS_CREATED],
      ],
    });
  useFirestoreConnect(queries);

  // const dispatch = useDispatch();
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products
  );

  // useEffect(() => {
  //   dispatch(updateCart(products));
  // }, [products]);

  if (!products) return <Loader />;
  return (
    <>
      <Router>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* Homepage */}
              <Route exact path={urls.home} component={ShopListStandard} />

              {/* Shop pages */}
              <Route exact path={urls.shop} component={ShopListStandard} />
              <Route
                exact
                path={urls.shopLandingPage}
                component={ShopListStandard}
              />
              <Route
                exact
                path={urls.productsByCategory}
                component={ShopListStandard}
              />

              {/* Shop product pages */}
              <Route
                path={urls.productDetail}
                render={(routeProps) => (
                  <Product
                    {...routeProps}
                    key={routeProps.match.params.id}
                    clickedProduct={routeProps.location.product}
                  />
                )}
              />

              {/* Other pages */}
              <Route path={urls.my_account} component={MyAccount} />
              <Route path={urls.my_orders} component={MyOrders} />
              <Route path={urls.login_register} component={LoginRegister} />
              <Route path={urls.forgot_password} component={ForgotPassword} />
              <Route path={urls.email_verified} component={VerifyEmail} />
              <Route path={urls.privacy} component={PrivacyPolicy} />
              <Route path={urls.terms_conditions} component={TermsConditions} />

              <Route path={urls.cart} component={Cart} />
              <Route path={urls.checkout} component={Checkout} />
              <Route path={urls.order_confirmed} component={OrderConfirmed} />
              <Route path={urls.order_failed} component={OrderFailed} />
              <Route
                path={urls.order_processing + urls.document_id_param}
                component={OrderProcessing}
              />
              <Route path={urls.not_found} component={NotFound} />
              <Route path={urls.delivery_returns} component={ShippingReturns} />
              <Route path={urls.faq} component={FAQ} />

              {/*Cognicum*/}
              <Route path={urls.booking_overview} component={BookingOverview} />

              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </ScrollToTop>
        <CookieBanner />
      </Router>
    </>
  );
};

export default App;
