import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../../urls";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../utils/theme";

const MobileNavMenu = () => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const { t } = useTranslation();
  return (
    <StyledNav>
      <ul>
        <li>
          <MenuItem to={urls.shop}>{t("collection")}</MenuItem>
        </li>
        <li>
          <MenuItemExternal href={urls.cognicum_about_us}>
            {t("about_us")}
          </MenuItemExternal>
        </li>
        <li>
          <MenuItemExternal href={urls.cognicum_agenda}>
            {t("agenda")}
          </MenuItemExternal>
        </li>
        <li>
          <MenuItemExternal href={urls.cognicum_contact}>
            {t("contact")}
          </MenuItemExternal>
        </li>
        {!auth.isEmpty ? (
          <>
            <li>
              <MenuItem to={urls.my_account}>{t("my_account")}</MenuItem>
            </li>
            <li>
              <MenuItem to={urls.my_orders}>{t("my_orders")}</MenuItem>
            </li>
          </>
        ) : (
          <>
            <li>
              <MenuItem to={{ pathname: urls.login_register, navKey: "login" }}>
                {t("login")}
              </MenuItem>
            </li>
            <li>
              <MenuItem
                to={{ pathname: urls.login_register, navKey: "register" }}
              >
                {t("register")}
              </MenuItem>
            </li>
          </>
        )}
      </ul>
    </StyledNav>
  );
};

const MenuItemExternal = styled.a`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
`;

const MenuItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 0 1rem 3rem 2rem;
`;

export default MobileNavMenu;
