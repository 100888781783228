import { transparentize } from "./helperFn";

export const colors = {
  primary: "#1d71b8",
  primary80: transparentize("#1d71b8", 0.8),
  secondary: "#f59f0f",
  text: "#3B3F33",
  btn: "#4B5A57",
  cta_btn: "#f59f0f",
  cta_btn_text: "#fff",
  standout_text: "#579b3c",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  clickable_text: "#0066ff",
  hamburger: "#1d71b8",
  menu_items: "#222",
};

export const styles = {
  btn_border_radius: "0px",
  card_border_radius: "10px",
  bullet_point_icon: "💎  ",
};
