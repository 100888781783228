import cartReducer from "./cartReducer";
import filterReducer from "./filterReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  firebaseReducer,
  firestoreReducer,
  cartData: cartReducer,
  filterData: filterReducer,
});

export default rootReducer;
