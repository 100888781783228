import { formatNameToURL } from "./formatters";
import _ from "lodash";
import i18n from "../translations/i18n";

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product discount price with default
export const getDiscountPriceWithDefault = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : price;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product) => {
  if (!!cartItems && cartItems.length > 0) {
    let productInCart = cartItems.filter(
      (single) => single.id === product.id
    )[0];
    if (cartItems.length >= 1 && productInCart) {
      return cartItems.filter((single) => product.id === single.id)[0].quantity;
    } else return 0;
  } else return 0;
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter(
        (product) =>
          product.category.filter(
            (single) =>
              formatNameToURL(single.toLowerCase()) === sortValue.toLowerCase()
          )[0]
      );
    }
    if (sortType === "tag") {
      return products.filter(
        (product) => product.tag.filter((single) => single === sortValue)[0]
      );
    }
    if (sortType === "color") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter((single) => single.color === sortValue)[0]
      );
    }
    if (sortType === "size") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter(
            (single) =>
              single.size.filter((single) => single.name === sortValue)[0]
          )[0]
      );
    }
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.price - b.price;
        });
      }
    }
  }
  return products;
};

export const sortProductsByPopular = (products) => {
  return _.orderBy(products, ({ purchased }) => purchased || 0, ["desc"]);
};

export const sortProductsByFeatured = (
  products,
  featuredLists,
  featuredType
) => {
  let currentList =
    !!featuredLists && featuredLists.find((item) => item.type === featuredType);
  if (
    !!currentList &&
    !!currentList.products &&
    currentList.products.length > 0
  ) {
    // Sort products by whether they are included in the featured list or not
    return _.sortBy(products, [
      (item) => {
        let index = _.findIndex(currentList.products, (id) => id === item.id);
        // If product is not found, modify index to be bigger than the featured products because
        // we sort the entire list by ascending order
        if (index === -1) index = index + products.length + 1;
        return index;
      },
    ]);
  } else return products;
};

// get individual element
const getIndividualItemArray = (array) => {
  return array.filter(function (v, i, self) {
    return i === self.indexOf(v);
  });
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products.map((product) => {
      return (
        product.category &&
        product.category.map((single) => {
          return productCategories.push(single);
        })
      );
    });
  return getIndividualItemArray(productCategories);
};

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  );
  filterButtons.forEach((item) => {
    item.classList.remove("active");
  });
  if (!!e.currentTarget) e.currentTarget.classList.add("active");
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const getFullItemPrice = (item) => {
  const answers = item.answers;
  if (!!answers && answers.length > 0) {
    let price = !!item.price ? item.price : item.paymentPrice;
    answers.forEach((answer) => {
      if (!!answer.options && answer.options.length > 0) {
        answer.options.forEach((item) => {
          if (item.checked && !!item.value) price += item.value;
        });
      }
    });
    return price;
  } else return !!item.price ? item.price : item.paymentPrice;
};

export const getFullItemDiscountPrice = (item) => {
  if (!!item.discount && item.discount > 0) {
    const answers = item.answers;
    const discount = item.discount;

    if (!!answers && answers.length > 0) {
      let price = item.price - item.price * (discount / 100);
      answers.forEach((answer) => {
        if (!!answer.options && answer.options.length > 0) {
          answer.options.forEach((item) => {
            if (item.checked && !!item.value) price += item.value;
          });
        }
      });
      return price;
    } else return item.price;
  } else return null;
};

export const getAdditionalAnswersPrice = (answers) => {
  if (!!answers && answers.length > 0) {
    let price = 0;
    answers.forEach((answer) => {
      if (!!answer.options && answer.options.length > 0) {
        answer.options.forEach((item) => {
          if (item.checked && !!item.value) price += item.value;
        });
      }
    });
    return price;
  } else return 0;
};

/**
 * Get product modal text used in My Orders to re-oder a order
 * @param changedProductList
 * @param notAvailableProductList
 * @returns {string}
 */
export const formatProductModalText = (
  changedProductList,
  notAvailableProductList
) => {
  if (
    changedProductList &&
    changedProductList.length > 0 &&
    notAvailableProductList === null
  ) {
    if (changedProductList.length > 1) {
      return i18n.t("modal.product_price_changed_plural");
    } else {
      return i18n.t("modal.product_price_changed");
    }
  } else if (
    notAvailableProductList &&
    notAvailableProductList.length > 0 &&
    changedProductList === null
  ) {
    if (notAvailableProductList.length > 1) {
      return i18n.t("modal.product_not_available_plural");
    } else {
      return i18n.t("modal.product_not_available");
    }
  } else if (
    changedProductList &&
    changedProductList.length > 0 &&
    notAvailableProductList &&
    notAvailableProductList.length > 0
  ) {
    return i18n.t("modal.product_has_changed_plural");
  }
};

/**
 * Filter products with searched value
 * @param products list of products
 * @param value searched value
 * @returns filtered product list
 */
export const searchProducts = (products, value) => {
  return products.filter(
    (product) =>
      product.name
        .toString()
        .toUpperCase()
        .indexOf(value.toString().toUpperCase()) !== -1
  );
};

/**
 * Check if product has minimum amount
 * If so set the minimum quantity to that amount
 * @param minAmount Product minimum amount
 * @param quantityCount The selected quantity
 * @returns {number|number|*}
 */
export const checkMinAmount = (minAmount, quantityCount) => {
  if (minAmount) {
    return quantityCount > minAmount ? quantityCount - 1 : minAmount;
  } else {
    return quantityCount > 1 ? quantityCount - 1 : 1;
  }
};

/**
 * Filter cart items depending on the product. Used in cart reducer.
 * @param cartItems
 * @param product
 * @returns {*}
 */
export const filterProduct = (cartItems, product) => {
  if (!!product.answers && product.answers.length > 0) {
    return cartItems.filter(
      (item) =>
        !!item.answers &&
        item.answers.length > 0 &&
        _.isEqual(item.answers, product.answers)
    )[0];
  } else return cartItems.filter((item) => item.id === product.id)[0];
};

/**
 * Find purchased product in CMS products to find the current variant of the product
 * (Product properties might have changed)
 * If there are changes in the purchased product and cms product, it will return nothing
 * @param productToFind
 * @param productList
 * @returns {*}
 */
export const findProduct = (productToFind, productList) => {
  return productList.find(
    (product) => product.id === productToFind.productId && product.stock > 0
  );
};

/**
 * Filter product by the hide variant property, used in Shoplist
 * @param products
 * @returns products filtered by the hideVariant property
 */
export const filterHiddenProducts = (products) => {
  return products.filter((product) => !product.hideVariant);
};
