export const ORDER_ID_PREFIX = "COG-";
export const ORDER_STATUS_CREATED = "CREATED";
export const ORDER_STATUS_PAID = "PAID";
export const ORDER_STATUS_SHIPPED = "SHIPPED";
export const ORDER_STATUS_DELIVERED = "COMPLETED";
export const ORDER_STATUS_RETURNED = "RETURNED";

export const ORDER_GENDERS = [
  { id: "MALE", i18nKey: "male" },
  { id: "FEMALE", i18nKey: "female" },
];
