export const DIGITAL_KISS = "Digital KISS";
export const DIGITAL_KISS_LOGO = "/assets/img/logo/logo-digitalkiss-text.png";
export const WEBSITE_NAME = "Cognicum";
export const WEBSITE_PHONE_NUMBER = "+31 85 111 88 44";
export const WEBSITE_EMAIL_ADDRESS = "info@cognicum.com";
export const WEBSITE_STREET_NUMBER = "Houtweg 29";
export const WEBSITE_ZIPCODE = "1251CS";
export const WEBSITE_CITY = "Laren";
export const GOOGLE_MAPS_LINK = "https://goo.gl/maps/a8dCjV29YyuqF9tW8";
export const GOOGLE_MAPS_LAT = "52.255559";
export const GOOGLE_MAPS_LON = "5.2103325";
export const SOCIAL_MEDIA = [
  {
    icon: "fa fa-facebook",
    link: "http://www.facebook.com/CognicumNL",
    name: "Facebook",
  },
  {
    icon: "fa fa-linkedin",
    link: "http://www.linkedin.com/company/Cognicum",
    name: "LinkedIn",
  },
  {
    icon: "fa fa-twitter",
    link: "http://www.twitter.com/Cognicum",
    name: "Twitter",
  },
];
export const LOGO_STORAGE_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Flogo.jpg?alt=media";
export const PRICE_RANGE_INDICATOR = "€";
export const COGNICUM_WEBSITE = "https://cognicum.com";
export const COGNICUM_CONTACT_URL = "https://cognicum.com/contact";
