// Meta tags titles
export const META_CHECKOUT_TITLE = "Afrekenen";
export const META_CART_TITLE = "Winkelwagen";
export const META_RESET_PASSWORD_TITLE = "Wachtwoord Resetten";
export const META_LOGIN_REGISTER_TITLE = "Inloggen - Registreren";
export const META_MY_ACCOUNT_TITLE = "Mijn Account";
export const META_ORDERS_TITLE = "Bestellingen";
export const META_NOT_FOUND_TITLE = "Niet Gevonden";
export const META_ORDER_CONFIRMED_TITLE = "Bestelling Bevestigd";
export const META_ORDER_FAILED_TITLE = "Betaling Mislukt";
export const META_PRIVACY_TITLE = "Privacy Beleid";
export const META_TERMS_CONDITIONS_TITLE = "Algemene Voorwaarden";
export const META_DELIVERY_RETURNS_TITLE = "Verzenden & retourneren";
export const META_FAQ_TITLE = "Veelgestelde Vragen";

// Meta tags descriptions
export const META_GENERIC_DESCRIPTION =
  "Cognicum organiseert evenementen voor management en bestuurders uit de zorg, het onderwijs, de overheid en multinationals.";
export const META_PRODUCT_DESCRIPTION =
  "bij Cognicum. Een breed aanbod van kennisreizen naar (zorg)congressen en inspirerende studiereisbestemmingen in binnen- en buitenland";
export const META_RESET_PASSWORD_DESCRIPTION =
  "Je wachtwoord vergeten? Vraag direct een nieuwe aan via deze pagina.";
export const META_LOGIN_REGISTER_DESCRIPTION =
  "Log in bij Cognicun om al je bestellingen in te zien!";
export const META_ORDERS_DESCRIPTION =
  "Al jouw Cognicum bestellingen op een rij.";
export const META_NOT_FOUND_DESCRIPTION =
  "Oeps, je bent naar een pagina gegaan die bij ons niet (meer) bestaat.";
