import i18n from "../../translations/i18n";
import { message } from "antd";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_MULTIPLE_TO_CART = "ADD_MULTIPLE_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const UPDATE_CART = "UPDATE_CART";

//add to cart
export const addToCart = (item, quantityCount, answers, omitNotification) => {
  return (dispatch) => {
    if (!omitNotification) message.success(i18n.t("added_to_cart"), 1);
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        answers: answers,
      },
    });
  };
};

// add multiple items to cart
export const addMultipleToCart = (products, omitNotification) => {
  return (dispatch) => {
    if (!omitNotification) message.success(i18n.t("added_to_cart"), 1);
    dispatch({
      type: ADD_MULTIPLE_TO_CART,
      payload: products,
    });
  };
};

//decrease from cart
export const decreaseQuantity = (item) => {
  return (dispatch) => {
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};

//delete from cart
export const deleteFromCart = (item) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};

//delete all from cart
export const deleteAllFromCart = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item) => {
  return item.stock > 0 ? item.stock : 0;
};

// update cart products with changes from CMS
export const updateCart = (products) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CART, payload: products });
  };
};
